.headerContext {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 0 var(--itens-spacing-ls);

  // gap: 2px;
}

.logout {
  align-self: center;
}

