html {
  padding: 2vh;
  display: flex;
    flex-direction: column;
    justify-content: center;
}

body {
  padding: 4%;
  border-radius: 25px;
  box-shadow: 1px 3px 12px 17px #888888;
  // height: auto;
  // height: 80vh;
  height: var(--HMain);
  overflow-y: scroll !important;
}

button {
  max-width: 200px;
}