.formCadastroDevedores {
    /* background-color: hsl(187, 27%, 71%); */
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputNome, .inputValor, .enviarDevedor  {
    width: 60%;
    margin: 10px 20% !important;
    height: 50px;
}
